export const CONTACT_DETAILS_NAME = 'CONTACT_DETAILS_NAME'
export const CONTACT_DETAILS_EMAIL = 'CONTACT_DETAILS_EMAIL'
export const CONTACT_DETAILS_PHONE_NUMBER = 'CONTACT_DETAILS_PHONE_NUMBER'
export const CONTACT_DETAILS_ADDRESS_LOOKUP = 'CONTACT_DETAILS_ADDRESS_LOOKUP'
export const CONTACT_DETAILS_MARKETING_CONSENT_REJECTED =
  'CONTACT_DETAILS_MARKETING_CONSENT_REJECTED'
export const CONTACT_DETAILS_ADDRESS_LINE_ONE =
  `${CONTACT_DETAILS_ADDRESS_LOOKUP}.addressFields.lineOne` as const
export const CONTACT_DETAILS_ADDRESS_LINE_TWO =
  `${CONTACT_DETAILS_ADDRESS_LOOKUP}.addressFields.lineTwo` as const
export const CONTACT_DETAILS_ADDRESS_CITY =
  `${CONTACT_DETAILS_ADDRESS_LOOKUP}.addressFields.city` as const
export const CONTACT_DETAILS_ADDRESS_POSTAL_CODE =
  `${CONTACT_DETAILS_ADDRESS_LOOKUP}.addressFields.postalCode` as const
export const CONTACT_DETAILS_ADDRESS_COUNTRY_CODE =
  `${CONTACT_DETAILS_ADDRESS_LOOKUP}.addressFields.countryCode` as const
export const CONTACT_REFERENCE_NUMBER_COOKIE_NAME = 'contactReferenceNumber'
