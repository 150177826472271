import { useTreatments } from '@splitsoftware/splitio-react'
import { isBotUser } from 'lib/user-agent/helpers'
import { isBrowser } from 'lib/browser/helpers'
import { CONTROL } from 'lib/split/constants'
import type { FlagName } from 'lib/split/types'

export const useExperiment = (flagName: FlagName): string => {
  const flag = useTreatments([flagName])[flagName]

  // If we're rendering via gatsby build, or if the user is a search engine
  // show the control variant.
  if (!isBrowser() || isBotUser()) {
    return CONTROL
  }

  return flag.treatment
}
