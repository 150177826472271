import identity from 'lodash/identity'

import { Address, LookupAddress } from './types'

export const addressIsEmpty = (values?: Address) => {
  if (!values) return true
  const { lineOne, lineTwo, city, postalCode, countryCode } = values
  return !lineOne && !lineTwo && !city && !postalCode && !countryCode
}

export const formatLoqateAddress = (address: LookupAddress) => {
  if (address.type !== 'Commercial' || !address.company) {
    return address
  }

  return {
    ...address,
    lineOne: address.company,
    lineTwo: [address.lineOne, address.lineTwo].filter(identity).join(', '),
  }
}
