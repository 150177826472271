import { CREMATION_TELEPHONE_NUMBER } from 'config'

import formatTelephoneNumber from 'lib/formatting/telephoneNumber'

export const createErrorMessage = (err: Error): string => {
  const errorMessages = []

  if (err.message.includes('phoneNumber'))
    errorMessages.push(
      'your phone number (currently we only accept GB phone numbers)'
    )

  const errorMessage = errorMessages.length
    ? ` Please check ${errorMessages.join(
        ' and '
      )} and try again or give us a call on ${formatTelephoneNumber(
        CREMATION_TELEPHONE_NUMBER
      )}`
    : ''

  return errorMessage
}
