import { useCallback, useRef, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import uniqueId from 'lodash/uniqueId'

type Record = {
  id: number
  type: string
  attributes: { [key: string]: unknown }
}

export type ResponseData = Record | Record[]

export type ResponseMeta = {
  messages?: { status: string; detail: string }[]
  cursors?: { pageStart?: string; pageEnd?: string }
  page?: { total?: number }
}

export type ResponseError = {
  code: string
  detail: string
  source?: { pointer?: string }
}

interface AxiosErrorWithErrors extends AxiosError {
  response?: AxiosResponse<{
    errors?: unknown
  }>
}

export const isAxiosError = (error: unknown): error is AxiosErrorWithErrors =>
  (error as AxiosErrorWithErrors).response?.data?.errors !== undefined

type InitialState<T> = {
  data?: T
  isLoading?: boolean
}

type Config = {
  url: string
  method?: string
  data?: { [key: string]: unknown }
  headers?: { [key: string]: string }
  sendToken?: boolean
}

export type StateType<T = ResponseData> = {
  data: T | null
  meta: ResponseMeta | null
  errors: AxiosErrorWithErrors | []
  isLoading: boolean
}

export type MakeRequestFn = <Type>(config: Config) => Promise<Type>

const useApi = <T = ResponseData>(
  initialState: InitialState<T> = {}
): [StateType<T>, MakeRequestFn] => {
  const [data, setData] = useState<T | null>(initialState.data ?? null)
  const [meta, setMeta] = useState(null)
  const [errors, setErrors] = useState<AxiosErrorWithErrors | []>([])
  const [isLoading, setIsLoading] = useState(initialState.isLoading || false)
  const lastRequestId = useRef<string>()

  const makeRequest = useCallback(async (config: Config) => {
    const requestId = uniqueId('use-api-')
    lastRequestId.current = requestId

    setIsLoading(true)
    setErrors([])
    const { url, method, headers, data: bodyData } = config

    try {
      const response = await axios({
        url,
        data: bodyData,
        method,
        headers,
      }).catch((error: AxiosError) => {
        throw error
      })

      if (lastRequestId.current !== requestId) {
        return response.data
      }

      setData(response.data.data)
      setMeta(response.data.meta)
      setIsLoading(false)
      return response.data
    } catch (error) {
      const requestErrors = isAxiosError(error)
        ? error.response?.data?.errors
        : []
      setErrors(requestErrors as AxiosErrorWithErrors)
      setIsLoading(false)
      throw error
    }
  }, [])

  return [{ data, errors, isLoading, meta }, makeRequest]
}

export default useApi
