import styled, { css } from 'styled-components'
import { Wrapper, P, Button } from '@farewill/ui'
import { FONT, GTR, COLOR, BORDER } from '@farewill/ui/tokens'

export const FloatingActiveStyles = css`
  top: 6px;
  font-size: ${FONT.SIZE.XS};
`

export const ResultDescription = styled.div`
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};
`

export const ResultLink = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  width: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${COLOR.BACKGROUND.FOG};
    cursor: pointer;
    text-decoration: none;
  }
`

export const NotFoundResultLink = styled(ResultLink)`
  text-align: right;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};
`

export const SearchLoadingMessage = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  min-height: 120px;
  width: 100%;
  text-decoration: none;
`

export const FloatingWrapper = styled(Wrapper)<{
  error: boolean
  hightlight: boolean
}>`
  position: relative;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -13px;
    width: 5px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    ${({ highlight }) =>
      highlight && `background-color: ${COLOR.STATE.WARNING};`}
    ${({ error }) => error && `background-color: ${COLOR.STATE.ERROR};`}
  }
`

export const FloatingLabel = styled.label<{ error?: boolean }>`
  position: absolute;
  color: ${COLOR.GREY.MEDIUM};
  top: 16px;
  left: ${GTR.S};
  pointer-events: none;
  white-space: nowrap;
  max-width: calc(100% - 28px);
  overflow: hidden;

  + * {
    margin-top: ${GTR.XXS};
  }

  ${({ error }) => error && `color: ${COLOR.STATE.ERROR};`}
`

export const FloatingInput = styled.input<{
  highlight?: boolean
  error?: boolean
  disabled?: boolean
}>`
  width: 100%;
  background-color: ${COLOR.WHITE};
  padding: 16px;
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.GREY.DARK};
  transition: border-color 0.2s ease-in-out;

  ${({ highlight }) => highlight && `border-color: ${COLOR.STATE.WARNING};`}
  ${({ error }) => error && `border-color: ${COLOR.STATE.ERROR};`}
  ${({ disabled }) => disabled && `background-color: ${COLOR.GREY.LIGHT};`}

  &:focus {
    border-color: ${COLOR.GREY.MEDIUM};
    ${({ highlight }) => highlight && `border-color: ${COLOR.STATE.WARNING};`}
    ${({ error }) => error && `border-color: ${COLOR.STATE.ERROR};`}

    & + ${FloatingLabel} {
      ${FloatingActiveStyles}
    }
  }
`

export const ResultsList = styled.ul`
  position: absolute;
  top: 58px;
  width: 100%;
  border: 1px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.S};
  box-shadow: ${BORDER.SHADOW.M};
  padding: 0;
  max-height: 400px;
  overflow: auto;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background-color: ${COLOR.GREY.LIGHT};
    width: calc(100% - ${GTR.L});
    position: absolute;
    top: 0;
    left: ${GTR.S};
  }
`

export const FloatingHint = styled(P)`
  margin-bottom: 0;
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.XS};
`

export const StyledFloatingHint = styled(FloatingHint)`
  margin-top: ${GTR.XXS};
`

export const StyledInputMethodButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};

  &:hover {
    text-decoration: underline;
  }
`

export const StyledLabel = styled.label<{ $error?: boolean }>`
  margin-bottom: ${GTR.XS};

  ${({ $error }) => $error && `color: ${COLOR.STATE.ERROR};`}
`

export const StyledError = styled.span`
  color: ${COLOR.STATE.ERROR};
  font-size: ${FONT.SIZE.XS};
`
