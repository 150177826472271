import React from 'react'
import { Grid } from '@farewill/ui'
import Input from 'components/Form/Input'

const AddressFields = ({ name }: { name: string }) => (
  <Grid>
    <Grid.Item>
      <Input
        name={`${name}.addressFields.lineOne`}
        label="Address line 1 *"
        required
      />
    </Grid.Item>
    <Grid.Item>
      <Input name={`${name}.addressFields.lineTwo`} label="Address line 2" />
    </Grid.Item>
    <Grid.Item spanFromM={4}>
      <Input
        name={`${name}.addressFields.city`}
        label="Town / city *"
        required
      />
    </Grid.Item>
    <Grid.Item spanFromM={4}>
      <Input
        name={`${name}.addressFields.countryCode`}
        label="Country *"
        required
      />
    </Grid.Item>
    <Grid.Item spanFromM={4}>
      <Input
        name={`${name}.addressFields.postalCode`}
        label="Postcode *"
        required
      />
    </Grid.Item>
  </Grid>
)

export default AddressFields
