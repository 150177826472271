import React from 'react'
import {
  StyledFloatingHint,
  StyledInputMethodButton,
} from '../styled-components'

const Hint = ({
  isSearching,
  setIsSearching,
}: {
  isSearching: boolean
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>
}) => (
  <StyledFloatingHint>
    {isSearching ? 'Start typing and choose from the list, or ' : 'Or '}
    <StyledInputMethodButton
      type="button"
      onClick={() => setIsSearching(!isSearching)}
    >
      {isSearching ? 'enter the address manually' : 'search for address'}
    </StyledInputMethodButton>
  </StyledFloatingHint>
)

export default Hint
