import React, { ReactElement } from 'react'
import { FONT } from '@farewill/ui/tokens'
import styled from 'styled-components'

import { CONTACT_DETAILS_MARKETING_CONSENT_REJECTED } from 'views/cremation/public/GetAGuideForm/constants'
import CheckboxField from '../Checkbox'

const StyledCheckbox = styled(CheckboxField)`
  text-align: left;
  font-size: ${FONT.SIZE.S};
  margin: 0;
`

export const MARKETING_CONSENT_LABEL =
  'We occasionally send discounts, offers and updates about our products. Tick to opt out.'

const MarketingConsentField = (): ReactElement => (
  <StyledCheckbox
    name={CONTACT_DETAILS_MARKETING_CONSENT_REJECTED}
    label={MARKETING_CONSENT_LABEL}
  />
)

export default MarketingConsentField
