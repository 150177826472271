import React, {
  HTMLInputTypeAttribute,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import get from 'lodash/get'
import axios from 'axios'
import { Grid } from '@farewill/ui'

import { FAREWILL_BACKSTAGE_URL } from 'config'
import useApi from 'lib/ui/useApi'
import { useDebounce } from 'lib/ui/useDebounce'
import LoaderSpinner from 'components/LoaderSpinner'
import { CONTACT_DETAILS_ADDRESS_LOOKUP } from 'views/cremation/public/GetAGuideForm/constants'
import { Address, LookupAddress, Result } from './types'
import { useFormContext } from '..'
import {
  FloatingWrapper,
  FloatingInput,
  ResultsList,
  SearchLoadingMessage,
  ResultLink,
  ResultDescription,
  NotFoundResultLink,
  StyledLabel,
  StyledError,
} from './styled-components'
import Hint from './components/hint'
import { addressIsEmpty, formatLoqateAddress } from './helpers'
import AddressFields from './components/addressFields'

const AddressForm = ({
  name,
  label,
  type,
  addressValues,
  onAddressChanged,
  disabled,
  required,
  validate,
}: {
  name: string
  label?: string
  type: HTMLInputTypeAttribute
  addressValues?: Address
  disabled?: boolean
  required?: boolean
  onAddressChanged?: (data: LookupAddress) => void
  validate?: (value: string) => string | undefined
}): ReactElement => {
  const { handleChange, setValues, setFieldError, values, errors, touched } =
    useFormContext()
  const [focused, setFocused] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const debouncedSearchQuery = useDebounce<{
    text?: string
    container?: string
  }>(searchQuery, 200)
  const [isSearching, setIsSearching] = useState(addressIsEmpty(addressValues))

  const searchEl = useRef<HTMLDivElement | null>(null)
  const currentValue = values[name]
  const error = errors[name]
  const isTouched = touched[name]
  const hasError = error && isTouched

  const getError = (value: string) => {
    if (required && !value) return 'This field is required.'
    return validate?.(value)
  }

  // This clears out any error on the search field, when user starts typing address manually
  useEffect(() => {
    if (!isSearching) {
      setFieldError(CONTACT_DETAILS_ADDRESS_LOOKUP, null)
    }
  }, [isSearching])

  useEffect(() => {
    setFieldError(name, getError(currentValue))
  }, [currentValue])

  const [{ data: searchResponse, isLoading }, makeRequest] = useApi<Result[]>()

  useEffect(() => {
    setValues({
      ...values,
      [`${name}.search`]: '',
      [`${name}.addressFields`]: addressValues,
    })
    if (!addressIsEmpty(addressValues)) {
      setIsSearching(false)
    }
  }, [setValues, name, addressValues])

  useEffect(() => {
    if (debouncedSearchQuery.text || debouncedSearchQuery.container) {
      makeRequest({
        url: `${FAREWILL_BACKSTAGE_URL}/api/address-lookups`,
        method: 'POST',
        data: {
          data: {
            type: 'address_lookups',
            attributes: debouncedSearchQuery,
          },
        },
      })
    }
  }, [debouncedSearchQuery, makeRequest])

  useEffect(() => {
    const handleCloseClick = (e: MouseEvent) => {
      if (
        focused &&
        searchEl?.current &&
        !searchEl.current.contains(e.target as Node)
      ) {
        setFocused(false)
      }
    }

    document.addEventListener('click', handleCloseClick)
    return () => document.removeEventListener('click', handleCloseClick)
  }, [focused])

  const onSearchResultClick = async ({ result }: { result: Result }) => {
    if (result.attributes.type === 'Address') {
      const address = await axios({
        url: `${FAREWILL_BACKSTAGE_URL}/api/address-lookups/${result.id}`,
      })
      const { attributes: addressData } = address.data.data
      const formattedAddress = formatLoqateAddress(addressData)
      const prefixedAddress = Object.entries(formattedAddress).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [`${name}.addressFields.${key}`]: value,
        }),
        {}
      )
      setIsSearching(false)
      setValues({
        ...values,
        [`${name}.search`]: '',
        ...prefixedAddress,
      })
      onAddressChanged?.(formattedAddress)
    } else {
      setSearchQuery({
        ...searchQuery,
        container: result.id,
      })
    }
  }

  const showResultsList = focused && get(values, name).search
  const hasCurrentSearchResults = !isLoading && searchResponse
  const isEnteringManually = !isSearching

  return (
    <Grid.Item>
      {isSearching && (
        <div ref={searchEl}>
          {label && (
            <StyledLabel htmlFor={`${name}.search`} $error={hasError}>
              {label} *
            </StyledLabel>
          )}
          <FloatingWrapper>
            <FloatingInput
              id={`${name}.search`}
              name={`${name}.search`}
              onFocus={() =>
                values[`${name}.search`].length > 0 && setFocused(true)
              }
              onChange={(e) => {
                handleChange(e)
                setFocused(true)
                setSearchQuery({ text: e.target.value })
              }}
              type={type}
              error={hasError}
              disabled={disabled}
            />
            {hasError && <StyledError>{error}</StyledError>}

            {showResultsList && (
              <ResultsList data-testid="address-results-list">
                {isLoading && (
                  <SearchLoadingMessage>
                    <LoaderSpinner centered height="120px" />
                  </SearchLoadingMessage>
                )}

                {hasCurrentSearchResults &&
                  searchResponse.map((result) => (
                    <ResultLink
                      key={result.id}
                      onClick={() => {
                        onSearchResultClick({ result })
                      }}
                    >
                      <div>{result.attributes.text}</div>
                      <ResultDescription>
                        {result.attributes.description}
                      </ResultDescription>
                    </ResultLink>
                  ))}

                <NotFoundResultLink onClick={() => setIsSearching(false)}>
                  Not found? <u>Enter the address manually</u>
                </NotFoundResultLink>
              </ResultsList>
            )}
          </FloatingWrapper>
        </div>
      )}

      {isEnteringManually && <AddressFields name={name} />}

      <Hint isSearching={isSearching} setIsSearching={setIsSearching} />
    </Grid.Item>
  )
}

export default AddressForm
