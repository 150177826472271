import { AxiosResponse } from 'axios'

import { getSavedTrackingParams } from 'lib/tracking/trackingParams'
import backstageClient from 'views/cremation/api/client'

const client = backstageClient({ cache: false }, 'GetAGuide')

const sendCallRequest = async <T>(
  formAttributes: T
): Promise<AxiosResponse> => {
  const trackingParameters = getSavedTrackingParams()

  try {
    return await client.post('/call-requests', {
      data: {
        type: 'call-requests',
        attributes: {
          trackingParameters,
          ...formAttributes,
        },
      },
    })
  } catch (error) {
    const { errors } = (error as any)?.response?.data
    const errorString = errors
      ? errors
          .map(
            (err: { source?: { pointer: string }; detail?: string }) =>
              err.source?.pointer || err.detail
          )
          .join(', ')
      : 'ContactRequestError'

    throw new Error(errorString)
  }
}

export default sendCallRequest
