import React from 'react'
import { H, P } from '@farewill/ui'

import ErrorBlock from 'components/Form/ErrorBlock'

interface Props {
  message?: string
}

const header = 'Sorry, something went wrong'

const ErrorMessage = ({ message }: Props): React.ReactElement => (
  <ErrorBlock>
    <H tag="h2" size="XS">
      {header}
    </H>
    <P size="S">{message}</P>
  </ErrorBlock>
)

export default ErrorMessage
