import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/gatsby'

import { FAREWILL_BACKSTAGE_URL } from 'config'
import { storageAvailable } from 'lib/storage/availability'

export const getCacheKey = (
  requestConfig: AxiosRequestConfig
): string | undefined => {
  const { method, data, url } = requestConfig

  if (method === 'get') {
    return url
  }

  if (method === 'post') {
    const json = typeof data === 'string' ? JSON.parse(data) : data
    const [cacheKey] = Object.values(json.data.attributes)

    return cacheKey as string
  }

  return undefined
}

export const readFromCache = (key: string): string | undefined | null => {
  if (!storageAvailable('sessionStorage')) return undefined

  const value = window.sessionStorage.getItem(key)
  const now = Date.now()

  if (!value) return undefined

  const { data, timestamp, expires } = JSON.parse(value)
  const ageOfDataInMilli = now - timestamp
  const expiresInMilli = expires * 1000

  if (ageOfDataInMilli > expiresInMilli) return null

  return data
}

export const writeToCache = (
  key: string,
  data: { data: unknown },
  expires = 3600 // in seconds
): void => {
  if (!storageAvailable('sessionStorage')) return

  const value = {
    data,
    timestamp: Date.now(),
    expires,
  }

  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export const deleteFromCache = (key: string): void => {
  if (!storageAvailable('sessionStorage')) return

  window.sessionStorage.removeItem(key)
}

const backstageClient = (
  options: {
    cache: boolean
  } = { cache: false },
  pageName: string
): AxiosInstance => {
  const client = axios.create({
    baseURL: `${FAREWILL_BACKSTAGE_URL}/api/`,
  })
  const { cache } = options

  client.interceptors.request.use(
    (config) => {
      const cacheKey = getCacheKey(config)

      if (!cacheKey) return config

      const cacheData = readFromCache(cacheKey)

      if (cacheData === null) deleteFromCache(cacheKey)

      if (cacheData) {
        // eslint-disable-next-line
        config.adapter = (config) =>
          new Promise((resolve) => {
            const res = {
              data: cacheData,
              status: 200,
              statusText: 'OK',
              headers: {
                /**
                 * The axios upgrade from 0.21.1 to 0.27.2 changes how this
                 * headers object is typed. Previously, these header values were
                 * typed as `any` which allowed the use of boolean values.
                 * This version changes the type to `string` or `string[]`.
                 * We have tried to define our own type that extends on Axios'
                 * definition which fixes the type error here but causes another
                 * error when passing this to `resolve()` at the end of this fn.
                 */
                cached: true as unknown as string,
                'content-type': 'application/json; charset=utf-8',
              },
              config,
              request: {},
            } as AxiosResponse

            return resolve(res)
          })
      }

      return config
    },
    (error) => error
  )

  client.interceptors.response.use(
    (response) => {
      const { headers, config: resConfig, data } = response
      const cacheKey = getCacheKey(resConfig)

      if (cache && cacheKey && !headers.cached)
        writeToCache(cacheKey, data, 300)

      return response
    },
    (error) => {
      Sentry.captureException(error, {
        contexts: { form: { public: pageName } },
      })
      return Promise.reject(error)
    }
  )

  return client
}

export default backstageClient
